// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-atlascopco-js": () => import("./../../src/pages/atlascopco.js" /* webpackChunkName: "component---src-pages-atlascopco-js" */),
  "component---src-pages-attest-js": () => import("./../../src/pages/attest.js" /* webpackChunkName: "component---src-pages-attest-js" */),
  "component---src-pages-bene-js": () => import("./../../src/pages/bene.js" /* webpackChunkName: "component---src-pages-bene-js" */),
  "component---src-pages-cobbled-climbs-js": () => import("./../../src/pages/cobbled-climbs.js" /* webpackChunkName: "component---src-pages-cobbled-climbs-js" */),
  "component---src-pages-echelon-js": () => import("./../../src/pages/echelon.js" /* webpackChunkName: "component---src-pages-echelon-js" */),
  "component---src-pages-ferret-js": () => import("./../../src/pages/ferret.js" /* webpackChunkName: "component---src-pages-ferret-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-matexi-js": () => import("./../../src/pages/matexi.js" /* webpackChunkName: "component---src-pages-matexi-js" */),
  "component---src-pages-maunga-js": () => import("./../../src/pages/maunga.js" /* webpackChunkName: "component---src-pages-maunga-js" */),
  "component---src-pages-sp-js": () => import("./../../src/pages/sp.js" /* webpackChunkName: "component---src-pages-sp-js" */),
  "component---src-pages-tw-js": () => import("./../../src/pages/tw.js" /* webpackChunkName: "component---src-pages-tw-js" */),
  "component---src-pages-werchter-js": () => import("./../../src/pages/werchter.js" /* webpackChunkName: "component---src-pages-werchter-js" */)
}

